@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap:100,200,300,400,500,600,700,800,900');

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #71717105;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

body::-webkit-scrollbar {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
